

/* Modal Content */
.modal {
  background-color: #282c34;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 3.5em;
  padding-right: 3.5em;
  padding-top: 2em;
  padding-bottom: 2em;
  border-radius: 8px;
  width: 60%;
  height: 80%;
  font-size: 100%;
  align-items: center; /* Vertical centering */
  justify-content: center; /* Horizontal centering */
  font-size: 15px;
}



@media (prefers-color-scheme: dark) {
  .ReactModal__Overlay {
    background-color: rgba(18, 18, 18, 0.85) !important; /* Dark mode background */
  }
}


.modal-link{
  font-size: 15px;
}

.fixed-button {
  font-family: Helvetica, sans-serif;
  background: #9c5419;;
  color: white;
  position: fixed;
  top: 1em;
  right: 1em;
  border: none;
  border-radius: 5px;
  font-size: 100%;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-weight: 500;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  text-align: "center";
  margin: "0 auto";
}

.fixed-button:hover {
    background: #c9732c;
}



/* Close Button */
.close-button {
  color: white;
  position: fixed;
  top: 1em;
  right: 1em;
  background: none;
  border: none;
  font-size: 100%;
  cursor: pointer;
}


.close-button:hover {
  color: #c9732c;
}

.scroll-div{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  justify-content: center; /* Horizontal centering */
  padding-right: 10%;
}

/* modalStyles.css */
.custom-modal-content {
  width: 60%; /* Set the width of the modal content */
  max-height: 80vh; /* Set the maximum height of the modal content */
  overflow: auto; /* Add scrollbars if the content exceeds the height */
  /* Additional styling for the modal content */
  background-color: rgb(13, 10, 54);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

a {
  color: white; /* Set the text color to white */
}



@media (prefers-color-scheme: light) {
  .fixed-button {
    background: #ee6c4d;
    color: white;
    border: 2px solid black; /* Adding a white solid border */
  }
  .fixed-button:hover {
    background: #f48b70;
  }
  .close-button {
    color: black;
  }
  .close-button:hover {
    color: #ee6c4d;
  }
  .modal {
    background-color: #fffaf1;
    color: black;
    border: 2px solid black; /* Adding a white solid border */
  }
  a {
    color: black;
  }
}
  