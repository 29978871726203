

table{
  width: 100%; /* Set the fixed width for the entire table */
  border: none;
  border-spacing: 0;
}

body{
  overflow-x: hidden;
}


.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #ffffff; /* White theme background */
  color: #000000; /* White theme text color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0; /* Start with hidden */
  transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
  font-size: 15px; /* Default font size */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
  z-index: 1000; /* Ensure it appears in front of other elements */
  border-radius: 50%; /* Make the button circular */

}

.back-to-top:hover {
  background-color: #d7d3d3; /* Light theme hover background */
}

.back-to-top.show {
  opacity: 1; /* Fade in when the button should be visible */
}


@media (prefers-color-scheme: light) {
  .back-to-top {
    border: 1px solid #000000; /* Add a black border in light mode */
  }
}

  
@media (prefers-color-scheme: dark) {
  .back-to-top {
    background-color: #ffffff; /* White theme background for dark mode */
    color: #000000; /* White theme text color for dark mode */
  }

  .back-to-top:hover {
    background-color: #d7d3d3; /* Light theme hover background for dark mode */
  }
}

@media (max-width: 768px) {
  .back-to-top {
    padding: 10px 20px; /* Increase padding for smaller screens */
    font-size: 15px; /* Increase font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .back-to-top {
    padding: 8px 16px; /* Increase padding for very small screens */
    font-size: 13px; /* Increase font size for very small screens */
  }
}

.department-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.department-container.expanded {
  max-height: 1000px; /* Adjust this value based on your content */
  transition: max-height 0.5s ease-in;
}

.department-table {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.department-item {
  padding: 5px 0;
}



/* .minimized-table td {
  display: none; /* Hide the table contents }*/

/* CSS for Minimized Table */
/* .minimized-table tr {
  display: none; /* Hide the table contents */
/* } */




.expanded-table {
  display: table;
}


.catalog-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 25px;
}

.department-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  width: 100%; /* Set a fixed width to fill the available space */
}

.department-item {
  /* Add any additional styling for department items */
  text-align: left; /* Align text to the left */
  box-sizing: border-box; /* Include padding and border in the item width */
}

/* Example to limit the width of the entire catalog container */
.catalog {
  max-width: 800px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the catalog in its parent container */
}



.section-title{
  font-size: 30px;
  font-weight: bold;
  color: white;
  text-align: left;
  cursor: pointer;
}


.button-container{
  display: flex;
  justify-content: flex-end;
}





.section-type{
  width: 20%
}

.section-number{
  width: 5%
}

.instructor{
  width: 20%
}

.enrollment{

}

.meeting-table{
  width: 100%;
}

.days{
}

.time{
}

.location{
}

.custom-table button{
  /* background: #e88c42; */
  color: #000000;
  font-size: 15px;
  padding: 0.5em 0.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
}

a {
  font-size: 16px;
}

p{
  font-size: 16px;
}

tr{
  font-size: 16px;
}


.catalogPage .custom-table {
  width: 1000px; /* Set the fixed width for the entire table */
  border: 1px solid #fff;
  border-radius: 3px;
  display: inline-block; 
}

.custom-table th, .custom-table td {
  padding: 8px; /* Adjust padding as needed */
  text-align: center; /* Center-align text within cells */
}
.custom-table td {
  padding: 8px; /* Adjust padding as needed */
  text-align: center;
}
.custom-table th {
  /* background-color: #656769; */
  background-color: #184276;
  text-align: center;
  /* background-color: #1d1d1e; */
}

.custom-table .column-names {
  position: sticky;
  /* top: 2.5em; */
}

.custom-table .title-header {
  position: sticky;
  /* top: 0; */

  z-index: 5; /* Ensure the header appears above the table content */
}

.custom-table .title-header th{
  position: sticky;
  text-align: left;
  /* top: 0; */
  
  z-index: 5; /* Ensure the header appears above the table content */
}

.course-title{
  font-size: 30px;
  font-weight: bold;
  padding: 20px;

  text-align: left;
  flex-grow: 1;
}

.external-buttons{
  width: 20%;
  /* min-width: 350px; */
    text-align: right;
}





.custom-table .expanded {
  display: table-row;
}

.custom-table .collapsed {
  display: none;
}




.custom-table tr {
  border: none; /* Remove all borders for data rows */
}

/* Style for even rows */
.custom-table tr:nth-child(even) {
  /* background-color: #1e2b53; Background color for odd rows */
  background-color: #1e2b53; /* Background color for odd rows */
  

}

/* Style for odd rows */
.custome-table tr:nth-child(odd) {
  background-color: #191c21; /* Background color for even rows */
  
}


/* Style for all rows of the inner table */
.custom-table tr:nth-child(even) .meeting-table tr,
.custom-table tr:nth-child(odd) .meeting-table tr {
  background-color: inherit; /* Inherit background color from outer table */
}

h3, h2, .custom-table tr{
  color: #fff;
}

h2{
  font-size: 30px;
}

ul {
  list-style-type: none;
}

.custom-table button:hover{
  background-color: #b4b0b0;
}




tr.title-header:hover th{
  cursor: pointer; /* Optional: Change the cursor to a pointer to indicate it's clickable */
  text-decoration: underline;
}

.toggle-button{
  /* background: #e88c42; */
  color: #000000;
  font-size: 15px;
  padding: 0.5em 0.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
}

.toggle-button:hover{
  background-color: #d7d3d3;
}







/* light / dark mode stuff */

@media (prefers-color-scheme: light) {
  .section-title{
    color: #000000;
  }
  h1, h2, h3, h4, h5 {
    color: #000000;
  }


  .catalogPage .catalog-button:hover{
    background-color: #c1baba;

  }

  .toggle-button{
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
  }

  .catalogPage .custom-table {
    border: 1px solid #000000;

  }
  .catalogPage .custom-table tr{
    color: #000000;
  }
  .catalogPage .custom-table th {
    /* background-color: #ffdeb3; */
    background-color: #fff4e6;
    background-color: #e7e7e7;

  }
  /* Style for even rows */
  .catalogPage .custom-table tr:nth-child(even) {
    /* background-color: #fff4e6; */
    background-color: #f8d7a9; 
  }
  
  /* Style for odd rows */
  .catalogPage .custom-table tr:nth-child(odd) {
  background-color: #ffffff; /* Background color for even rows */

  }

  /* Style for all rows of the inner table */
  .catalogPage .custom-table tr:nth-child(even) .meeting-table tr,
  .catalogPage .custom-table tr:nth-child(odd) .meeting-table tr {
      background-color: inherit; /* Inherit background color from outer table */
  }


  .catalogPage .custom-table button{
    /* background: #e88c42; */
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
  }

}

.catalogPage .subject {
  font-size: 35px;
}

@media (max-width: 1000px) {
  .catalogPage h2{
    font-size: 14px
  }

  .catalogPage h3{
    font-size: 14px;
  }

  
  .catalogPage .section-title{
    font-size: 20px;
    cursor: pointer;
  }

  /* .catalogPage .section-title:hover{
    cursor: pointer;
  } */



  .catalogPage .custom-table {
    width: 90%;
  }

  @media (max-width: 768px) {
    .catalogPage .hide-button {
        display: none;
    }
  }

  .catalogPage .catalog-button {
    text-align: right;
    font-size: 8px;
    white-space: nowrap; 
  }

  .toggle-button {
    font-size: 8px;
  }

  .catalogPage .external-buttons {
    padding: 0.2em 0.2em;
    font-size: 8px;
    width: 5%;
  }

  .catalogPage .course-title {
      font-size: max(min(calc(4.5vw - 10px), 30px), 10px);
  }

  .catalogPage .custom-table button{
    font-size: max(min(calc(4vw - 12px), 15px), 8px);
  }


  .catalogPage table {
    font-size: 8px;
    border: none;
    border-collapse: collapse;
  }

  .catalogPage .subject {
    font-size: max(min(calc(4.5vw - 10px), 35px), 15px);
  }
  
  .catalogPage a {
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage p{
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage tr{
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage .table-header {
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage .section-type{
    width: 10%;
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage .section-number{
    width: 10%;
    padding: 0px;
    font-size: max(min(calc(4vw - 12px), 15px), 7px);
  }

  .catalogPage .instructor{
    width: 10%;
    padding: 0px;
    font-size: max(min(calc(4vw - 12px), 15px), 8px);
  }

  .catalogPage .enrollment{
    width: 1%;
    padding: 0px;
    font-size: max(min(calc(4vw - 12px), 15px), 8px);

  }
  .catalogPage .meeting-table{
    width: 10%;
  }

  .catalogPage .days{
    width: 9%;
  }

  .catalogPage .time{
    width: 9%;
  }

  .catalogPage .location{
    width: 9%;
  }
}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  .section-title{
    font-size: 20px;
  }
  .catalog-section {
    padding: 10px; /* Add some padding to the section for narrower screens */
  }
  .department-table {
    grid-template-columns: 1fr; /* One column when the screen is under 768 pixels */
  }
  .department-item {
    flex-basis: 100%; /* Set it to 100% to display one item per row on mobile */
  }
  strong{
    font-size: 14px;
  }

}
