.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  padding: 15px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 0.8s linear;
  }
}


.nav-button{
  padding: 1em;
  font-size: 30px;
  font-weight: 400;
  text-decoration: none;
}

.nav-bar {
  padding-bottom: 0.5em;
}


.underlined{
  text-decoration: underline;
  /* border-bottom: 1px solid black; Set the desired thickness and color */
}


.App-header {
  background-color: #191c21;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


body {
  background-color: #191c21;
}


.App-Title {
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-size: 90px;
  font-weight: 500;
  font-size: 50px;
  margin-bottom: 0.25em;
}


@media (prefers-color-scheme: light) {
  .App-header {
    background-color: #fff;
  }
  body {
    background-color: #fff;
  }
  .App-Title {
    color: #191c21;
  }
}


.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* 
@media (min-width: 768px) {
  .nav-bar{
    width: 70%;
  }

  .nav-button{
    padding: 0.1em;
    font-weight: 500;
    text-decoration: none;
  }
} */


.github-icon {
  /* position: fixed; */
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 0.5rem;
  /* transform: translateX(-50%); */
  z-index: 1000; 
}


.github-icon img {
  width: 1.5em; /* Adjust the size as needed */
  height: 1.5em;
  padding-bottom: 0.1em;
}


/* Use media queries to adjust the font size based on screen width */
@media (min-width: 1000px) {
  .App-Title {
    font-size: 90px;
  }
}