body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

textarea {
  width: 50vw; /* Use a percentage for dynamic width */
  height: 5vh; /* Use a percentage of viewport height for dynamic height */
  border: none;
  background: #1e2b53;
  color: #fff;
  font-size: 20px;
  padding: 2rem;
  resize: none;
  border-radius: 5px;
  border: 2px solid white; /* Adding a white solid border */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}

.searchButton {
  background: #9c5419;
  color: #fff;
  font-size: 20px;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
}

.searchButton:hover {
  background: #c9732c;
}

.moreLikeThisButton {
  background: #9c5419;
  color: #fff;
  /* padding: 1rem; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  margin-top: 1.5rem;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 600;
  /* font-size: 17px; */
  font-size: 70%;
  
}

.moreLikeThisButton:hover {
  background: #c9732c;
}

select {
  background: #1e2b53;
  color: #fff;
  font-size: 20px;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 1rem;
  text-align: center;
  border: 2px solid white; /* Adding a white solid border */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: bold;
}

select:hover{
  background-color: #184276;
}

.course-description {
  color: white;
  font-size: 4vw;
  margin-bottom: 1.5rem;
  text-align: left;
  padding-left: 1rem;
  padding-right: 1rem;
}


.character-count {
  position: relative;
  color: gray;
  font-size: 12px;
}

.accordion {
  width: 80%;
  max-width: 2000px;
  margin: 2rem auto;  
  /* padding-bottom: 20px; */
  
  
}

.accordion-title {
  display: block; /* Change to block to stack elements */
  text-align: center;
  cursor: pointer;
  background-color: #184276;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 1rem;
  text-decoration: none; /* Remove underline for links */
  color: white;
}

.accordion-title-content {
  white-space: wrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide text that overflows the container */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflowed text */
  font-size: 1.5rem; 
  font-weight: bolder;
  
  font-size: 1.5rem; 
  font-weight: bolder;
  
}

.title-text {
  flex: 1; /* Allow the text to take up available space */
  
  
}

.accordion-title:hover {
  background-color: #45648a;
  text-decoration: underline;
}

.accordion-content {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 1rem 1rem;
}

.accordion-content {
  background-color: #162246;
  
}

.loadingText {
  color: white;
}


.search-info{
  font-size: 1rem;
  font-weight:'bold';
  margin-left:'1rem';
  margin-right:'1rem';
  color: white;
  font-weight: 700;
}

@media screen and (max-width: 80%) {
  body {
    font-size: 12px;
  }

  .accordion {
    width: 90%;
  }
}



@media (max-width: 786px){
  .search-info{
    font-size: 0.72em;
  }

}

/* Use media queries to adjust the font size based on screen width */
@media (min-width: 600px) {
  .course-description {
    font-size: calc(14px + 1vw); /* Adjust the formula as needed */
  }
}

@media (min-width: 1000px) {
  .course-description {
    font-size: calc(8px + 1vw); /* Adjust the formula as needed */
  }
  .accordion {
    width: 70%;
  }

}

@media (min-width: 2000px) {
  .course-description {
    font-size: calc(1vw); /* Adjust the formula as needed */
  }
  .accordion {
    width: 70%;
  }
}


@media (prefers-color-scheme: light) {

  .search-info{
    color: black;
  }
  textarea {
    background: #fff;

    border: 3px solid black; /* Adding a white solid border */
    color: #000000;
  }

  .course-description {
    color: black;
  }
  .loadingText {
    color: black;
  }
  .searchButton{
    background: #ee6c4d;
    border: 2px solid black; /* Adding a white solid border */
  }
  .searchButton:hover {
    background: #f48b70;
  } 
  select{
    background-color: #fff;

    color: #000000;
    border: 2px solid black; /* Adding a white solid border */
  }


  select:hover{
    background-color: #fff4e6;
    background-color: #ffdeb3;
  }

  .accordion-title {
    background-color: #ffdeb3;


    color: #000000;
    border: 2px solid black; /* Adding a white solid border */
    border-bottom: none;
  }
  .accordion-title:hover {
    background-color: #fff4e6;

  }
  .accordion-content {
    background-color: #fff;
    color: #000000;
    border: 2px solid black; /* Adding a white solid border */
    border-top: none;
  }
  .moreLikeThisButton {
    background: #ee6c4d;
    border: 2px solid black; /* Adding a white solid border */
  }
  .moreLikeThisButton:hover {
    background: #f48b70;
  }
}


